import React from 'react';
import './Images.css';

export default function Images() {
    return (
        <div>
        <h1>Images</h1>
        <body>
            <img src='' alt='My-Images'/>
        </body>
        </div>
    )
}